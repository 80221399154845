/* Copyright (C) 2023 Greenbone AG
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Affero General Public License
 * as published by the Free Software Foundation, either version 3
 * of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
import {isDefined} from 'gmp/utils/identity';

export class ResourceName {
  constructor({id, name, type}) {
    this.id = id;
    this.name = name;
    this.type = type;
  }

  static fromElement(element, type) {
    const {_id, name} = element;

    return new ResourceName({
      id: isDefined(_id) ? _id : '',
      name: isDefined(name) ? name : '',
      type: type,
    });
  }
}

export default ResourceName;
